import React from "react";
import {
  Box,
  Heading,
  Text,
  Container,
  useColorModeValue,
} from "@chakra-ui/react";

export const Story = () => {
  return (
    <Box bg={useColorModeValue("gray.100", "gray.700")} id="Мояісторія">
      <Container maxW="7xl" py={16}>
        <Box>
          <Heading
            as="h2"
            fontSize="4xl"
            mb={4}
            textAlign="center"
            color={"#8ABFBB"}
          >
            Моя історія
          </Heading>
          <Text fontSize="lg" mb={8}>
            Психологія почала цікавити мене з дуже юного віку, я завжди хотіла
            краще зрозуміти себе, стати більш впевненою у собі, не дратуватись
            так часто через дрібниці. <br />
            Тому після декількох курсів з психології для підлітків, безліч
            прочитаних книг я поступила на психолога. Протягом навчання в
            університеті я почала шукати напрями психотерапії, які б мені
            імпонували і я могла би застосовувати у роботі з дітьми, підлітками
            та дорослими. <br />
            Я вивчала різні напрями, такі як арт-терапія, тілесно-орієнтовану
            терапія, казкотерапія, ігротерапія, пісочна терапія. У процесі
            навчання я не тільки навчилася терапевтичних методів та навичок, але
            нарешті змогла зрозуміти себе та пропрацювати у власній терапії зі
            спеціалістами в цих напрямах теми, які мене хвилювали та заважали
            жити повним життям. Я пройшла шлях від соціальної тривожності до
            роботи із задоволенням із великими групами людей. <br />Я вірю, що
            для кожного клієнта потрібно підбирати різні методи та техніки, які
            спрацюють саме для нього. Тому я не перестаю навчатись, наразі я
            проходжу навчання позитивній психотерапії, навчаюсь працювати з
            військовими та маю ще багато планів на навчання. Не знаю, чи
            вистачить життя навчитись усього, чого хочу навчитись :) <br />
            Мої клієнти говорять, що я, як спеціаліст вдумлива, емпатійна,
            чутлива та розуміюча.
            <br />У вільний від роботи час я люблю танцювати сальсу, проводити
            час за містом, готувати різноманітні страви та дегустувати вино.
          </Text>
        </Box>
      </Container>
    </Box>
  );
};
