import { ReactNode } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Container,
  useColorModeValue,
  SimpleGrid,
} from "@chakra-ui/react";

const Testimonial = ({ children }: { children: ReactNode }) => {
  return <Box>{children}</Box>;
};

const TestimonialContent = ({ children }: { children: ReactNode }) => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      boxShadow={"lg"}
      p={8}
      rounded={"xl"}
      align={"center"}
      pos={"relative"}
      _after={{
        content: `""`,
        w: 0,
        h: 0,
        borderLeft: "solid transparent",
        borderLeftWidth: 16,
        borderRight: "solid transparent",
        borderRightWidth: 16,
        borderTop: "solid",
        borderTopWidth: 16,
        borderTopColor: useColorModeValue("white", "gray.800"),
        pos: "absolute",
        bottom: "-16px",
        left: "50%",
        transform: "translateX(-50%)",
      }}
    >
      {children}
    </Stack>
  );
};

const TestimonialText = ({ children }: { children: ReactNode }) => {
  return (
    <Text
      textAlign={"center"}
      color={useColorModeValue("gray.600", "gray.400")}
      fontSize={"sm"}
    >
      {children}
    </Text>
  );
};

const TestimonialAvatar = ({
  name,
  title,
}: {
  name: string;
  title: string;
}) => {
  return (
    <Flex align={"center"} mt={8} direction={"column"}>
      <Stack spacing={-1} align={"center"}>
        <Text fontWeight={600}>{name}</Text>
        <Text fontSize={"sm"} color={useColorModeValue("gray.600", "gray.400")}>
          {title}
        </Text>
      </Stack>
    </Flex>
  );
};

export default function WithSpeechBubbles() {
  const testimonials = [
    {
      text: "Прекрасний психолог, дуже чуйна та розуміюча, має гарний підхід та дає цікаві вправи для розв'язання проблем. Дуже задоволена !",
      name: "Наталія",
      title: "",
    },
    {
      text: "Гарний професіонал і дуже приємна, як людина! Сеанси проходять у приємній атмосфері без тиску!",
      name: "Михайло",
      title: "",
    },
    {
      text: "Мені дуже подобається працювати з цим психологом, ми тільки на початку терапії, але це справді допомагає мені",
      name: "Анна",
      title: "",
    },
    {
      text: "Раджу Олену як фахівчиню, яка вдумливо та емпатично підходить до вирішення завдань.",
      name: "Олеся",
      title: "",
    },
    {
      text: "Велика вдячність Олені за можливість працювати себе. Прийшла взагалі не розумія про що говорити і в чому проблема. Пройшли всі сеанси і я як квітка в стакані, якій налили води і вона підняла бутон і розквітла- по відчуттям) пропрацювали те що не здавалось на перший погляд важливим, а виявилось основою питань які турбували. Величезна благодійність за таку неймовірну працю та підтримку психологічну в такі тяжкі емоційні часи 🥰",
      name: "Олександра",
      title: "",
    },
  ];

  return (
    <Box bg={useColorModeValue("gray.100", "gray.700")} id="Відгуки">
      <Container maxW={"7xl"} py={16}>
        <Heading
          as="h2"
          fontSize="4xl"
          mb={4}
          textAlign="center"
          color={"#8ABFBB"}
        >
          Відгуки
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
          {testimonials.map((testimonial, index) => (
            <Testimonial key={index}>
              <TestimonialContent>
                <TestimonialText>{testimonial.text}</TestimonialText>
              </TestimonialContent>
              <TestimonialAvatar
                name={testimonial.name}
                title={testimonial.title}
              />
            </Testimonial>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
}
