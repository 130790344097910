import { Icon } from "@chakra-ui/react";
import {
  FaFacebook,
  FaInstagram,
  FaTelegram,
  FaViber,
  FaWhatsapp,
} from "react-icons/fa";

const TelegramUsername = "olena_valiente";
const ViberNumber = "+380977128178";
const Whatsapp = "+573007462282";
const Instagram = "https://www.instagram.com/olenka_valiente/";
const Facebook = "https://www.facebook.com/olenshev/";

export const SocialMediaLinks = () => {
  return (
    <div style={{ display: "flex" }}>
      <a
        href={`https://t.me/${TelegramUsername}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon
          as={FaTelegram}
          fontSize="30px"
          cursor="pointer"
          color={"#24A1DE"}
        />
      </a>
      <span style={{ margin: "0 5px", color: "#999" }}>|</span>
      <a
        href={`viber://contact?number=${ViberNumber}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon as={FaViber} fontSize="30px" cursor="pointer" color={"#7360f2"} />
      </a>
      <span style={{ margin: "0 5px", color: "#999" }}>|</span>
      <a
        href={`https://wa.me/${Whatsapp}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon
          as={FaWhatsapp}
          fontSize="30px"
          cursor="pointer"
          color={"green"}
        />
      </a>
      <span style={{ margin: "0 5px", color: "#999" }}>|</span>
      <a href={Instagram} target="_blank" rel="noopener noreferrer">
        <Icon
          as={FaInstagram}
          fontSize="30px"
          cursor="pointer"
          color={"#515BD4"}
        />
      </a>
      <span style={{ margin: "0 5px", color: "#999" }}>|</span>
      <a href={Facebook} target="_blank" rel="noopener noreferrer">
        <Icon as={FaFacebook} fontSize="30px" cursor="pointer" color={"blue"} />
      </a>
    </div>
  );
};
