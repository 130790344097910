import "./index.css";
//import './wdyr';
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
//import reportWebVitals from './reportWebVitals';

import { ChakraProvider } from "@chakra-ui/react";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ChakraProvider>
    <App />
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
