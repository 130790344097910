import React from "react";
import {
  Box,
  Heading,
  Text,
  Container,
  useColorModeValue,
} from "@chakra-ui/react";

export const Reunion = () => {
  return (
    <Box
      bg={useColorModeValue("gray.100", "gray.700")}
      id="Чогоочікувативідзустрічі?"
    >
      <Container maxW="7xl" py={16}>
        <Box>
          <Heading
            as="h2"
            fontSize="4xl"
            mb={4}
            textAlign="center"
            color={"#8ABFBB"}
          >
            Чого очікувати від зустрічі?
          </Heading>
          <Text fontSize="lg" mb={8}>
            Зустріч зі мною відбувається в атмосфері глибокої довіри та взаємної
            підтримки. Клієнт отримує те, чого він шукав — бажання, щоб його
            слухали, підтримували та допомагали знайти відповіді на свої
            питання. Перший сеанс є ознайомчим і спрямованим на встановлення
            взаємної довіри. Я вітаю нових клієнтів і дозволяю їм поділитися
            своїми потребами та очікуваннями від роботи. Якщо у вас вже є запит
            або конкретна проблема, ми можемо обговорити її. Якщо немає
            конкретного запиту, це абсолютно нормально, ми разом сформулюємо
            його у процесі роботи. На наших зустрічах ми будемо багато
            розмовляти, використовувати тілесні та арт-практики. Це допоможе нам
            розкрити глибинні емоції та розуміння, які є ключовими для
            розв'язання проблем. У кінці сесій я зазвичай даю домашнє завдання,
            яке допомагає практикувати нові навички та поглиблювати особистий
            розвиток. Важливо розуміти, що під час нашої роботи у Вас можуть
            виникати різні почуття, включаючи емоційну вразливість, незручність,
            непевність або злість. Це може лякати, але я завжди підтримаю Вас та
            допоможу справитись з цими почуттями. Якщо ви відчуваєте, що настав
            час ближче познайомитись з собою - то я запрошую вас до співпраці.
            Запишіться на першу зустріч зі мною і розпочнемо цей захопливий шлях
            разом.
          </Text>
        </Box>
      </Container>
    </Box>
  );
};
