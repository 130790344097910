import {
  Box,
  Container,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { SocialMediaLinks } from "./SocialMediaLinks";
const Logo = (props: any) => {
  return (
    <img
      src="/logo_olena_shevchenko-vertical-sin-espacios.png"
      alt="Logo"
      style={{
        width: "250px",
        height: "6vh",
      }}
      width="250px"
      height="6vh"
    />
  );
};

export function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Container
        as={Stack}
        maxW={"6xl"}
        py={4}
        spacing={4}
        justify={"center"}
        align={"center"}
      >
        <Logo />
        {/*
            <Stack direction={"row"} spacing={6}>
            <Link href={"#"}>Home</Link>
            <Link href={"#"}>About</Link>
            <Link href={"#"}>Blog</Link>
            <Link href={"#"}>Contact</Link>
            </Stack>
        */}
      </Container>

      <Box
        borderTopWidth={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.700")}
      >
        <Container
          as={Stack}
          maxW={"6xl"}
          py={4}
          direction={{ base: "column", md: "row" }}
          spacing={4}
          justify={{ base: "center", md: "space-between" }}
          align={{ base: "center", md: "center" }}
        >
          <Text>© {currentYear} Olena Shevchenko. All rights reserved</Text>
          <Stack direction={"row"} spacing={6}>
            <SocialMediaLinks />
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
