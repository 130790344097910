import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import About from "./pages/About";
//import Sessions from "./pages/Sessions";
//import Contact from "./pages/Contact";
import mixpanel from "mixpanel-browser";
import { Header } from "./pages/Header";
import Testimonials from "./pages/Testimonials";
import { Price } from "./pages/Price";
import { Temas } from "./pages/Temas";
import { Reunion } from "./pages/Reunion";
import { Footer } from "./pages/Footer";
import { Story } from "./pages/Story";

const App: React.FC = () => {
  const [isMounted, setIsMounted] = useState(false);
  //console.log("🚀 ~ isMounted:", isMounted);

  useEffect(() => {
    if (!isMounted) {
      mixpanel.init("8a35a4aa83acac315ff3c0ded8fd881a");
      mixpanel.track("Page Viewed", { isFirstVisit: true });
      setIsMounted(true);
    }
  }, [isMounted]);

  const sections = ["Home", "About", "Services", "Contact"];

  const [hashValue, setHashValue] = useState("");

  /*
  const url = window.location.href;
  useEffect(() => {
    if (url.includes("#")) {
      const partes = url.split("#");
      const textoDecodificado = decodeURIComponent(partes[1]);
      setHashValue(textoDecodificado);
    } else {
      setHashValue("");
    }
  }, [url]);
  */
  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      if (hash) {
        const textoDecodificado = decodeURIComponent(hash.substring(1));
        setHashValue(textoDecodificado);
      } else {
        setHashValue("");
      }
    };
    handleHashChange();
    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  return (
    <Box>
      <Header sections={sections} hashValue={hashValue} />
      <Box>
        <About />
        <Story />
        <Temas />
        {/*<Sessions />*/}
        <Reunion />
        <Price />
        <Testimonials />
        {/*<Contact />*/}
        <Footer />
      </Box>
    </Box>
  );
};

export default App;
