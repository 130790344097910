import React from "react";
import { Box, Heading, Text, Container, Flex } from "@chakra-ui/react";

const About: React.FC = () => {
  return (
    <Box py={[8, 16]} id="Промене" bgColor={"#8ABFBB"} color={"white"}>
      <Container maxW="7xl">
        <Flex flexWrap="wrap" alignItems="center">
          <Box>
            <img
              src="olena_shevchenko.png"
              alt="Olena Shevchenko"
              style={{
                borderRadius: "md",
                boxShadow: "lg",
              }}
            />
          </Box>
          <Box flex="1" mr={[0, 8]} mb={[8, 0]}>
            <Heading as="h2" fontSize="4xl" mb={4} textAlign="center">
              Про мене
            </Heading>
            <Text fontSize="lg">
              Вітаю на моїй сторінці!
              <br />
              Мене звати Олена і я психологиня та арт-терапевтка, спеціалістка з
              питань еміграції, адаптації до нових умов життя, тривожності та
              стосунків з собою.
            </Text>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default About;
