import { ReactElement } from "react";
import {
  Box,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  Flex,
  Container,
  Heading,
} from "@chakra-ui/react";
import { FcCheckmark } from "react-icons/fc";
import { AiFillCloseCircle } from "react-icons/ai";

interface FeatureProps {
  text: string;
  icon: ReactElement;
}

const Feature = ({ text, icon }: FeatureProps) => {
  return (
    <Stack
      align={"center"}
      p={4}
      bg={"white"}
      borderRadius={"md"}
      boxShadow={"lg"}
    >
      <Flex w={16} h={16} align={"center"} justify={"center"} mb={1}>
        {icon}
      </Flex>
      <Text color={"gray.600"}>{text}</Text>
    </Stack>
  );
};

export function Temas() {
  return (
    <Container maxW="7xl" py={16} id="Теми,зякимияпрацюю">
      <Box>
        <Heading
          as="h2"
          fontSize="4xl"
          mb={4}
          textAlign="center"
          color={"#8ABFBB"}
        >
          Теми, з якими я працюю
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
          <Feature
            icon={<Icon as={FcCheckmark} w={10} h={10} />}
            text={
              "Мені добре вдається працювати переважно з такими запитами як тривожність, еміграційні складнощі, пропрацювання самооцінки, стосунки з батьками (сепарація), складні стосунки з оточуючими, проживання складних емоцій. "
            }
          />
          <Feature
            icon={<Icon as={FcCheckmark} w={10} h={10} />}
            text={
              "Також я консультую ЛГБТ+ клієнтів, дітей та підлітків. У роботі я відзначаю прогрес клієнта, використовую творчі методи в роботі, задаю домашні завдання, допомагаю знаходити різноманітні рішення проблем та багато уваги приділяю темі дитинства."
            }
          />
          <Feature
            icon={<Icon as={AiFillCloseCircle} w={10} h={10} color={"red"} />}
            text={
              "Я не працюю з темами залежностей, розлучення, супроводу тяжких захворювань."
            }
          />
        </SimpleGrid>
      </Box>
    </Container>
  );
}
