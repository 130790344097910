import {
  Box,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  Container,
} from "@chakra-ui/react";

export function Price() {
  return (
    <Box py={16} id="Оплатапослуг">
      <Container maxW="container.lg">
        {/* Utilizar Container con el ancho apropiado */}
        <VStack spacing={8} textAlign="justify">
          <Heading
            as="h2"
            fontSize="4xl"
            mb={4}
            textAlign="center"
            color={"#8ABFBB"}
          >
            Оплата послуг
          </Heading>
          <Box
            mb={4}
            shadow="base"
            borderWidth="1px"
            borderColor={useColorModeValue("gray.200", "gray.500")}
            borderRadius={"xl"}
            p={8} // Agregar relleno para los márgenes
          >
            <Text fontSize="lg" color={"gray.500"}>
              Вартість однієї моєї консультації дорівнює <b>1000 гривень</b>.
            </Text>
            <Text fontSize="lg" color={"gray.500"}>
              Періодично я беру декількох кейсових або бюджетних клієнтів для
              яких пропоную знижки на консультацію, особливо це стосується
              людей, постраждалих в наслідок війни. Звертайтесь, обговоримо ваш
              випадок та можливість знижки.
            </Text>
            <Text fontSize="lg" color={"gray.500"}>
              Оплата проводиться на банківський рахунок ФОП або через посилання.
            </Text>
          </Box>
        </VStack>
      </Container>
    </Box>
  );
}
